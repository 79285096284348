<template>
  <v-card class="mb-5" color="info" dark>
    <div v-show="show">
      <v-card-title>Welcome, {{ currentUser.meta.first_name }} 🎉</v-card-title>
      <v-card-text v-if="isEmployer">
        <p class="mb-5">We’re glad to have you on board with us. Are you looking for a hustle free way of getting your job done and that too without burning a hole in your pocket? Here at Gradbee, we offer that and much more.</p>
        <h3>Few things you can start with:</h3>
        <ul class="body-2 mb-5">
          <li class="mt-2">✍️ Post about an internship and other work opportunities for free (for now).</li>
          <li class="mt-2">💬 Connect with students across the globe through direct messages.</li>
          <li class="mt-2">📌 Bookmark student profiles of your preference.</li>
        </ul>
        <p class="my-5">If you have any queries, feel free to ask as at help@gradbee.com</p>
        <v-btn x-large depressed block @click="submitUser" :loading="loading" :disabled="loading">Let's Get Started! <v-icon small>mdi-arrow-right</v-icon></v-btn>
      </v-card-text>
      <v-card-text v-else>
        <p class="mb-5">We hope that you’re ready to dive into the endless pool of realtime opportunities that our community has curated just for you.</p>
        <p class="mb-5" v-if="!$vuetify.breakpoint.smAndDown">Here at Gradbee, not only can you upgrade your skills but also raise the bar of your resumè. <b>Enticing, isn’t it? 😃</b></p>
        <h3>Few things you can start with:</h3>
        <ul class="body-2 mb-5">
          <li class="mt-2">⚡ Apply on internships, fresher jobs and other opportunities.</li>
          <li class="mt-2">📌 Bookmark projects of your preference.</li>
          <li class="mt-2">💬 Connect with other students and employers through direct messages.</li>
          <li class="mt-2">✍️ Post an opportunity that you come across elsewhere. (for instance, while reading newspaper 🗞️ etc.)</li>
        </ul>
        <b>Pro-tip: Employers see complete / verified profiles first. 😉</b>
        <p class="my-5">If you have any queries, feel free to ask as at help@gradbee.com</p>
        <v-btn x-large depressed block @click="submitUser" :loading="loading" :disabled="loading">Let's Get Started! <v-icon small>mdi-arrow-right</v-icon></v-btn>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
const UPDATE_USER = require('../../graphql/updateUser.graphql')
export default {
  data () {
    return {
      show: true,
      loading: false
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isEmployer () {
      return this.currentUser.role === 'employer'
    }
  },
  methods: {
    async submitUser () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              welcome_note_done: true
            }
          }
        })
        this.show = false
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
