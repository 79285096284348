<template>
  <v-card class="mb-5 abc">
    <div class="d-flex align-center justify-start">
      <div v-if="!$vuetify.breakpoint.xs">
        <v-card-text class="pa-3">
          <v-btn icon disabled>
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </v-card-text>
      </div>
      <v-btn class="pr-1" text @click="categoryFilter" large :tile="$vuetify.breakpoint.xs">
        <v-badge
          color="pink"
          :content="appliedCategories"
          inline
          v-show="appliedCategories"
        >
          Categories
        </v-badge>
        <span v-show="!appliedCategories">
          Categories
        </span>
        <v-icon v-show="!categoriesClicked">mdi-chevron-down</v-icon>
        <v-icon v-show="categoriesClicked">mdi-chevron-up</v-icon>
      </v-btn>
      <v-btn class="pr-1" text depressed  @click="tagFilter" large :tile="$vuetify.breakpoint.xs">
        <v-badge
          color="pink"
          :content="appliedTags"
          inline
          v-show="appliedTags"
        >
          Tags
        </v-badge>
        <span v-show="!appliedTags">
          Tags
        </span>
        <v-icon v-show="!tagsClicked">mdi-chevron-down</v-icon>
        <v-icon v-show="tagsClicked">mdi-chevron-up</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon class="mr-3" @click="removeFilters" v-show="appliedCategories || appliedTags"><v-icon>mdi-close-box</v-icon></v-btn>
    </div>
    <v-slide-y-transition hide-on-leave >
      <v-sheet v-if="tagsClicked || categoriesClicked" elevation="0">
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="filtersForm" @submit.prevent="validate" :id="`filters-modal`" class="v-text-field--rounded form">
            <v-row v-if="categoriesClicked">
              <v-col v-if="$apollo.queries.categories.loading" :cols="12">
                <v-card>
                  <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <div class="d-flex flex-wrap">
                  <v-checkbox hide-details class="mr-2" v-model="newCategories" :label="item.name" v-for="(item, i) in userCategories" :key="i" :value="item.id"></v-checkbox>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="tagsClicked">
              <v-col>
                <div class="tags pt-5 mb-5" v-if="$apollo.queries.tags.loading">
                  <v-card>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </v-card>
                </div>
                <div class="tags" v-else>
                  <h3>Select Tags</h3>
                  <div class="d-flex flex-wrap">
                    <v-checkbox hide-details class="mr-2" v-model="newTags" :label="item.name" v-for="(item, i) in tags" :key="i" :value="item.id"></v-checkbox>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-btn @click.prevent="applyFilters" block color="secondary" large class="mt-5">
              Apply Filters
            </v-btn>
          </v-form>
        </v-card-text>
      </v-sheet>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import breakPoints from '../../mixins/breakPoints'
import autocomplete from '../../mixins/autocomplete'
import mapsMixin from '../../mixins/mapsMixin'

const CATEGORIES = require('../../graphql/queries/categories.graphql')
const TAGS = require('../../graphql/queries/tags.graphql')

export default {
  apollo: {
    categories: {
      query: CATEGORIES,
      skip () {
        return !this.categoriesClicked
      }
    },
    tags: {
      query: TAGS,
      skip () {
        return !this.tagsClicked
      }
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      feedItems: [
        {
          color: 'green',
          title: 'My Feed',
          active: false
        },
        {
          color: 'green',
          title: 'Everything',
          active: false
        }
      ],
      newTags: [],
      categoriesClicked: false,
      tagsClicked: false,
      loading: false,
      appliedCategories: 0,
      appliedTags: 0,
      newCategories: []
    }
  },
  computed: {
    userCategories () {
      return this.categories && this.categories.map(item => item.term)
    },
    userSelected () {
      return this.currentUser.profile.project_category.map(item => item.id) || []
    }
  },
  methods: {
    categoryFilter () {
      this.tagsClicked = false
      this.categoriesClicked = !this.categoriesClicked
    },
    tagFilter () {
      this.categoriesClicked = false
      this.tagsClicked = !this.tagsClicked
    },
    applyFilters () {
      this.$emit('apply', {
        categories: this.newCategories,
        tags: this.newTags
      })
      this.appliedCategories = this.newCategories.length
      this.appliedTags = this.newTags.length
      this.tagsClicked = false
      this.categoriesClicked = false
    },
    removeFilters () {
      this.$emit('apply', {
        categories: [],
        tags: []
      })
      this.newCategories = []
      this.newTags = []
      this.appliedCategories = 0
      this.appliedTags = 0
      this.tagsClicked = false
      this.categoriesClicked = false
    }
  },
  mixins: [breakPoints, autocomplete, mapsMixin]
}
</script>

<style scoped>
  .tag {
    margin-right: 0.75rem;
    margin-top: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 3px !important;
  }
</style>
