<template>
  <v-container fluid class="px-1 pa-sm-5">
    <v-card class="pa-4 mb-5">
      <div class="d-flex align-center">
        <router-link :to="{name: 'myProfile'}" class="mr-4 flex-grow-0 text-decoration-none">
          <v-avatar size="40" v-if="currentUser && currentUser.meta" :color="currentUser.meta.et_avatar_url ? '' : 'info'">
            <v-img :src="currentUser.meta.et_avatar_url" :lazy-src="currentUser.meta.et_avatar_url" v-if="currentUser.meta.et_avatar_url"></v-img>
            <span class="white--text" v-else>{{ currentUser.meta.first_name[0].toUpperCase() }}{{ currentUser.meta.last_name[0].toUpperCase() }}</span>
          </v-avatar>
        </router-link>
        <div class="flex-grow-1">
          <v-btn block elevation="0" large rounded left class="text-left" :to="{ name: 'createPost' }">
            Create a Post
          </v-btn>
        </div>
      </div>
    </v-card>
    <filters @apply="handleFilters" :current-user="currentUser" v-if="currentUser && currentUser.meta"/>
    <welcome :current-user="currentUser" v-if="currentUser.meta && !welcomeNoteDone" />
    <feed :posts="projects.list" v-if="projects && !refetch" />
    <div class="skeleton-loaders">
      <skeleton @triggerIntersected="showMore" v-show="!$apollo.queries.projects.loading && !allFetched" :loaders="1"/>
      <skeleton v-show="$apollo.queries.projects.loading && !allFetched" :loaders="1" />
    </div>
    <div v-if="allFetched && projects.list.length === 0" class="text-center">
      <h3 class="text-center mb-5">😐 Sorry, we can't find any projects with these filters.</h3>
      <v-avatar size="320" max-width="100%" rounded>
        <v-img :src="require('@/assets/images/sorry.gif')" />
      </v-avatar>
    </div>
  </v-container>
</template>

<script>
import Feed from '../components/Feed'
import Welcome from '../components/Feed/Welcome'
import Filters from '../components/Feed/Filters.vue'
import Skeleton from '../components/Utils/Skeleton'
import PROJECTS from '../graphql/projects.graphql'

export default {
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      page: 1,
      number: 7,
      categories: [],
      type: [],
      allFetched: false,
      refetch: false
    }
  },
  mounted () {
    this.$emit('reFetchCurrentUser')
  },
  async activated () {
    try {
      if (this.shouldFetchFeed) {
        this.refetch = true
        await this.$store.dispatch('setFetchFeed', false)
        await this.$apollo.queries.projects.refetch()
      }
    } catch (e) {
      return e
    } finally {
      this.refetch = false
    }
  },
  apollo: {
    projects: {
      query: PROJECTS,
      variables () {
        return {
          paged: 1,
          number: this.number,
          type: this.type,
          categories: this.categories
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    welcomeNoteDone () {
      return this.currentUser && this.currentUser.meta && this.currentUser.meta.welcome_note_done
    },
    shouldFetchFeed () {
      return this.$store.state.fetchFeed
    }
  },
  methods: {
    async showMore () {
      this.page++
      // Fetch more data and transform the original result
      try {
        const res = await this.$apollo.queries.projects.fetchMore({
          // New variables
          variables: {
            paged: this.page,
            number: this.number,
            type: this.type,
            categories: this.categories
          },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              projects: {
                __typename: previousResult.projects.__typename,
                // Merging the list
                list: [...previousResult.projects.list, ...fetchMoreResult.projects.list]
              }
              // meta: {
              //   __typename: previousResult.meta.__typename,
              //   // Merging the tag list
              //   list: [...fetchMoreResult.meta.list]
              // }
            }
          }
        })
        this.allFetched = res.data.projects.list.length === 0
      } catch (e) {
        console.log(e)
        return e
      }
    },
    handleFilters (filters) {
      this.projects = []
      this.page = 1
      this.allFetched = false
      this.categories = filters.categories
      this.type = filters.tags
    }
  },
  metaInfo () {
    return {
      title: 'Feed | Gradbee'
    }
  },
  components: {
    Feed,
    Skeleton,
    Welcome,
    Filters
  }
}
</script>

<style scoped>

</style>
